import actions from './actions'
import mutations from './mutations'

const state = {
  domain: '',
  tenantName: '',
  login: { weChat: {}, dingTalk: {} },
  maintenance: {},
  maintenanceType: 'portal',
  destUrl: '', // 登录成功后跳转的地址
  showCaptcha: '', // 是否显示校验码输入框和图片
  errorMessage: '', // 错误信息
  coolDown: 0 // 再次获取手机验证的冷却时间
}

const store = new Vuex.Store({
  state,
  actions,
  mutations
})
store.$http = axios

export default store
