//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { showMessage, modalOpen, confirm } from 'sparrow-popup'
import MaintenanceDialog from './dialog-maintenance.vue'
import http from '../../../utils/http'

export default {
  components: {
    'cmp-maintenance-dialog': MaintenanceDialog
  },
  data () {
    return {
      inDevEnv: false,
      isSMSLoginType: false,
      loginId: '',
      password: '',
      captchaCode: '',
      smsCode: '',
      otpCode: '',
      verifyImg: '/cas/captcha.do',
      exceptionPhone: '',
      showScanCode: false,
      scanCodeExpried: false,
      scanCodeInterval: 0,
      exceptionCount: 0
    }
  },
  computed: {
    adminLogin () {
      this.inDevEnv = document.location.href.indexOf('dev.mctech.vip') > -1 || document.location.href.indexOf('test.mctech.vip') > -1
      return !this.isSMSLoginType && (this.loginId === 'admin' || this.loginId.indexOf('admin@') === 0)
    },
    forceMobileLogin () {
      if (this.$store.state.login.forceMobileLogin || this.$store.state.login.defaultLoginType === 'sms-code') {
        this.isSMSLoginType = true
      }
      return this.$store.state.login.forceMobileLogin
    },
    isDingTalkCode () {
      const domain = this.$store.state.domain
      const result = this.$store.state.login.dingTalk[domain]
      if (result && result.pc.type === 'qrCode') {
        document.getElementById('dingTalkAccessId').value = result.pc.appId
        document.getElementById('dingTalkRedirectUri').value = encodeURIComponent(result.pc.redirectUrl.replace('{domain}', domain))
        setTimeout(() => {
          const url = `https://oapi.dingtalk.com/connect/qrconnect?appid=${result.pc.appId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${document.getElementById('dingTalkRedirectUri').value}`
          console.log(url)
          DDLogin({
            id: 'dingTalkCode', // 这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
            goto: encodeURIComponent(url), // 请参考注释里的方式
            style: 'border:none;background-color:#FFFFFF;',
            width: '365',
            height: '400'
          })
        }, 500)
      }
      return result
    },
    isDingTalkButton () {
      const domain = this.$store.state.domain
      const result = this.$store.state.login.dingTalk[domain]
      return result && result.pc.type === 'button'
    },
    exceptionUser () {
      const index = document.location.href.indexOf('/login.html?phone=')
      // this.exceptionPhone = document.location.href.substr(index + 18, 11)
      if (index > -1 && !this.exceptionCount) {
        this.exceptionCount = this.exceptionCount + 1
        showMessage('账号异常, 请联系系统管理员', 'danger')
      }
      // return index > -1
      return false
    },
    tenantName () {
      const name = this.$store.state.tenantName
      for (let i = 0; i < document.styleSheets[0].cssRules.length; i++) {
        if (document.styleSheets[0].cssRules[i].selectorText === '.login-title') {
          const length = name.replace(/[\u0391-\uFFE5]/g, 'aa').length
          let size = (480 - (this.$store.state.maintenance.status ? 35 : 0)) / length
          if (size > 34) {
            size = 34
          }
          document.styleSheets[0].cssRules[i].style.fontSize = size + 'px'
          break
        }
      }
      return name
    },
    showCaptcha () {
      // 每次登录出错，都要重新加载个检验码
      this.changeImg()
      return this.$store.state.showCaptcha
    },
    destUrl () {
      if (this.$store.state.destUrl) {
        window.sessionStorage.clear() // 清除 session 数据
        const redirectParams = this.$store.state.destUrl.indexOf('?') >= 0 ? '&enterProduct=intelli-worksite' : '?enterProduct=intelli-worksite'
        document.location.replace(this.$store.state.destUrl + redirectParams)
      }
      return this.$store.state.destUrl
    },
    errorMessage () {
      if (this.$store.state.errorMessage) {
        showMessage(this.$store.state.errorMessage, 'danger')
        // 使用用户名密码方式登录才会验证是否需要显示校验码
        // if (!this.isSMSLoginType) {
        const domain = this.$store.state.domain
        const incDomain = domain && this.loginId.indexOf(`@${domain}`) > -1
        this.$store.dispatch('checkCaptcha', {
          type: this.isSMSLoginType ? 'sms-code' : 'password',
          sms: this.isSMSLoginType,
          key: this.loginId + (incDomain || this.isSMSLoginType ? '' : (domain ? '@' + domain : ''))
        })
        // }
      }
      const result = this.$store.state.errorMessage
      this.$store.state.errorMessage = ''
      return result
    },
    coolDown () {
      if (this.$store.state.coolDown > 0) {
        setTimeout(this.decCoolDown, 1000)
      }
      return this.$store.state.coolDown
    },
    maintenance () {
      return this.$store.state.maintenance
    }
  },
  watch: {
    maintenance (value) {
      const maintenance = this.$store.state.maintenance
      // 已存在 maintenance-portal/product-time 这个 cookie，则返回 true，表明不显示 checkBox
      if (maintenance.startTime) {
        const cookieName = `maintenance-${this.$store.state.maintenanceType}-${maintenance.startTime.replace(' ', '-').replace(/:/g, '-')}`
        if (document.cookie.indexOf(`${cookieName}=true`) === -1) {
          modalOpen('#maintenance-modal')
          return
        }
      }
      if (document.querySelector('#maintenance-modal')) {
        document.querySelector('#maintenance-modal').style.display = 'none'
        document.querySelector('#maintenance-modal').style.visibility = 'hidden'
        document.querySelector('#maintenance-modal > .dialog').style.opacity = 0
      }
    }
  },
  methods: {
    async mctechCodePage () {
      // 如果已显示，则不处理，否则显示
      if (!this.showScanCode || this.scanCodeExpried) {
        this.scanCodeInterval = 0
        const result = await http.get('/services/public/scan-code')
        console.log(result)
        document.querySelector('.scancode').src = `/services/public/scan-code?ticket=${result.ticket}`
        this.showScanCode = true
        this.scanCodeExpried = false
        const me = this
        // 定时获取登录信息
        this.scanCodeInterval = setInterval(async function () {
          const validResult = await http.get(`/services/public/scan-code?statusTicket=${result.ticket}`)
          if (validResult.expired) {
            // token 过期，显示说明和刷新按钮
            clearInterval(me.scanCodeInterval)
            me.scanCodeExpried = true
          } else if (validResult.success) {
            // 登录成功，进行跳转
            clearInterval(me.scanCodeInterval)
            document.location.replace(validResult.url)
          }
        }, 3 * 1000) // 每3秒轮询一次
      }
    },
    dingTalkCodePage () {
      const domain = this.$store.state.domain
      const result = this.$store.state.login.dingTalk[domain]
      if (result && result.pc.type === 'button') {
        const url = `https://oapi.dingtalk.com/connect/qrconnect?appid=${result.pc.appId}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${encodeURIComponent(`https://${domain}.mctech.vip/cas/third/private/dingTalk/qr-login?agentId=${result.pc.agentId}`)}`
        // const url = `https://gslq.mctech.vip/cas/third/private/dingTalk/login?agentId=10001&code=1d4226f73b4e319b99c10e5993a66fbe`
        document.location.href = url
      } else {
        console.log(`${result.pc.type} 不是 button 方式。`)
      }
    },
    showMaintenance () {
      document.querySelector('#maintenance-modal').style.display = ''
      document.querySelector('#maintenance-modal').style.visibility = 'visible'
      document.querySelector('#maintenance-modal > .dialog').style.opacity = 100
    },
    blur () {
      if (this.loginId) {
        const domain = this.$store.state.domain
        const incDomain = domain && this.loginId.indexOf(`@${domain}`) > -1
        this.$store.dispatch('checkCaptcha', {
          type: this.adminLogin ? 'pwd-otp' : this.isSMSLoginType ? 'sms-code' : 'password',
          sms: this.adminLogin ? false : this.isSMSLoginType,
          key: this.loginId + (incDomain || this.isSMSLoginType ? '' : (domain ? '@' + domain : ''))
        })
      }
    },
    changeImg () {
      this.verifyImg = '/cas/captcha.do?ts=' + new Date().getTime()
    },
    getSMSCode () {
      if (this.coolDown === 0) {
        if (!/^1\d{10}$/.test(this.loginId)) {
          showMessage('手机号无效', 'danger')
        } else {
          this.$store.dispatch('getSMSCode', {
            loginId: this.loginId
          }).then(() => {
            document.querySelector('.sms-code').focus()
          })
        }
      }
    },
    decCoolDown () {
      this.$store.commit('decCoolDown')
    },
    login () {
      const me = this
      // if (document.location.href.indexOf('/login.html?phone=') > -1) {
      //   // 后台验证短信内容
      //   this.$store.dispatch('verifySMSKey', { smsKey: me.smsCode })
      //   return
      // }
      // 参数验证包
      const _valid = {
        password: function () {
          if (me.adminLogin) {
            return me.loginId && me.password && me.otpCode && (!me.$store.state.showCaptcha || me.captchaCode)
          } else {
            return me.loginId && me.password && (!me.$store.state.showCaptcha || me.captchaCode)
          }
        },
        sms: function () {
          return me.loginId && me.smsCode
        }
      }

      let incDomain = false
      if (me.loginId && me.$store.state.domain && me.loginId.indexOf('@' + me.$store.state.domain) > -1) {
        incDomain = true
      }

      // 登录函数包
      const _login = {
        password: function () {
          const domain = me.$store.state.domain
          const options = {
            loginId: me.loginId + (incDomain ? '' : (domain ? '@' + domain : '')),
            password: me.password,
            captchaCode: me.captchaCode
          }
          if (me.adminLogin) {
            options.otpCode = me.otpCode
          }
          me.$store.dispatch('loginPassword', options)
        },
        sms: function () {
          me.$store.dispatch('loginSMS', {
            loginId: me.loginId,
            smsCode: me.smsCode,
            captchaCode: me.captchaCode
          })
        }
      }

      // 清除原错误信息，这样出错时因为 errorMessage 有变化，就可以调用 errorMessage()，触发 checkCaptcha
      this.$store.commit('setErrorMessage', '')
      // 获取登录方式对应的参数验证函数和登录函数
      const validFn = this.isSMSLoginType ? _valid.sms : _valid.password
      const loginFn = this.isSMSLoginType ? _login.sms : _login.password
      if (validFn()) {
        loginFn()
      }
    },
    changeLoginType () {
      // this.$store.commit('setCoolDown', 0)
      this.$store.commit('setErrorMessage', '')
      this.isSMSLoginType = !this.isSMSLoginType
      if (this.isSMSLoginType) {
        // 手机验证码登录判断输入的是否为手机号，如果不是，则清空，否则保留
        if (!/^1\d{10}$/.test(this.loginId)) {
          this.loginId = ''
        }
      }
      this.blur()
    },
    resetPassword () {
      window.open('/password.html')
    },
    logout () {
      document.location.replace('/logout')
    }
  }
}
