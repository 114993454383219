import http from '../../../utils/http'
import { confirm } from 'sparrow-popup'

const domain = ''

const actions = {
  getLoginConfig: function (store) {
    http
      .get('/services/public/login-config', { href: document.location.href })
      .then(result => {
        if (result.redirect) {
          document.location.replace(result.redirect)
        } else {
          store.commit('setLoginConfig', result)
        }
      })
      .catch(error => {
        store.commit('setErrorMessage', error.data.desc)
      })
  },
  checkCaptcha: function (store, params) {
    http
      .get('/cas/check-captcha.do', params)
      .then(result => {
        store.commit('setShowCaptcha', result ? new Date().getTime() : '')
      })
      .catch(error => {
        store.commit('setErrorMessage', error.data.desc)
      })
  },
  getSMSCode: function (store, data) {
    const phoneNumber = data.loginId + (/^1\d{10}$/.test(data.loginId) ? '' : domain)
    http
      .post('/cas/sms/send-sms-once.do', { phone: phoneNumber, domain: store.state.domain || undefined })
      .then(result => {
        store.commit('setCoolDown', 60)
      })
      .catch(error => {
        store.commit('setErrorMessage', error.data.desc)
      })
  },
  loginPassword: async function (store, data) {
    const loginId = data.loginId + (/^1\d{10}$/.test(data.loginId) ? '' : domain)
    if (data.otpCode) {
      http
        .post('/cas/otp/login.do', {
          username: loginId,
          password: data.password,
          otpcode: data.otpCode,
          captcha: data.captchaCode
        })
        .then(result => {
          store.commit('setDestUrl', result.service)
        })
        .catch(error => {
        // console.log('loginPassword error')
          store.commit('setErrorMessage', error.data.desc)
        })
      return
    }
    const login = function () {
      const lengthValidator = data.password.length >= 8 && data.password.length <= 20
      const validators = [
        /[a-z]/.test(data.password),
        /[A-Z]/.test(data.password),
        /[0-9]/.test(data.password),
        new RegExp(/[`~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/).test(data.password)
      ]
      const maxCount = validators.filter(v => v).length
      const passwordValid = lengthValidator && maxCount >= 3
      if (store.state.login.forcePasswordStrategy === true && !passwordValid) {
        store.commit('setErrorMessage', '密码只能由8-20个字母,并且包含三种字符（大小写，数字，特殊字符）')
        return
      }
      http
        .post('/cas/login.do', {
          username: loginId,
          password: data.password,
          captcha: data.captchaCode
        })
        .then(result => {
          if (!passwordValid) {
            http.get(result.service).then(validResult => {
              if (store.state.login.forcePasswordStrategy === 'change') {
                confirm('当前登录密码不符合所属企业的密码强度要求，请及时修改。', act => {
                  document.location.replace(`/logout?service=${act === 'ok' ? encodeURIComponent('/password.html?redirect=/') : '/'}`)
                })
              } else {
                http.get('/services/user-need-change-password').then(change => {
                  if (change) {
                    confirm('当前登录密码不符合所属企业的密码强度要求，请及时修改。', act => {
                      document.location.replace(`/logout?service=${act === 'ok' ? encodeURIComponent('/password.html?redirect=/') : '/'}`)
                    })
                  } else {
                    store.commit('setDestUrl', '/')
                  }
                })
              }
            })
          } else {
            store.commit('setDestUrl', result.service)
          }
        })
        .catch(error => {
        // console.log('loginPassword error')
          store.commit('setErrorMessage', error.data.desc)
        })
    }
    if (store.state.login.integrationChecker) {
      // 检测用户是否集成了，如果是，跳转到根组织
      http.get('/services/public/is-integration', { loginId, domain }).then(result => {
        if (result.isIntegration) {
          document.location.replace(document.location.href.replace('login.html', ''))
        } else {
          login()
        }
      }).catch(error => {
        // console.log('loginPassword error')
        store.commit('setErrorMessage', error.data.desc)
      })
    } else {
      login()
    }
  },
  loginSMS: function (store, data) {
    const phoneNumber = data.loginId + (/^1\d{10}$/.test(data.loginId) ? '' : domain)
    const login = function () {
      http
        .post('/cas/sms/login.do', {
          phone: data.loginId,
          domain: store.state.domain || undefined,
          code: data.smsCode,
          captcha: data.captchaCode
        })
        .then(result => {
          store.commit('setDestUrl', result.service)
        })
        .catch(error => {
          store.commit('setErrorMessage', error.data.desc)
        })
    }
    if (store.state.login.integrationChecker) {
      // 检测用户是否集成了，如果是，跳转到根组织
      http.get('/services/public/is-integration', { phoneNumber, domain }).then(result => {
        if (result.isIntegration) {
          document.location.replace(document.location.href.replace('login.html', ''))
        } else {
          login()
        }
      }).catch(error => {
        // console.log('loginPassword error')
        store.commit('setErrorMessage', error.data.desc)
      })
    } else {
      login()
    }
  },
  verifySMSKey: function (store, data) {
    http
      .post('/services/sms-key', { smsKey: data.smsKey })
      .then(result => {
        document.location.replace('/')
      })
      .catch(error => {
        store.commit('setErrorMessage', error.data.desc)
      })
  }
}

export default actions
