<template>
  <div class="modal-mask" id="maintenance-modal" popup-action="none">
    <div class="dialog"
      style="position: absolute; left: 40px; top: 100px; width: 400px; height: 480px; box-sizing: border-box; transform: none;">
      <div class="dialog-header">
        <span>维护公告</span>
        <input v-show="isShow" id="refreshHidden" type="checkbox" style="position: absolute; right: 140px;" checked @click="hiddenClick" />
        <label v-show="isShow" id="spanForRefreshHidden" for="refreshHidden"
          style="position: absolute; right: 80px; font-size: 14px; line-height: 20px;">不再显示</label>
        <a class="dialog-close-btn" @click="hide"></a>
      </div>
      <div class="dialog-body" style="position: absolute; flex-direction: column; bottom: 0px; display: flex;">
        <span style="padding-bottom: 8px;">维护时间：从 {{ startTime }} 到 {{ endTime }}</span>
        <div style="flex: 1 1 auto; height: 90%;">
          <textarea :value="content" style="width: 100%; height: 100%; resize: none; padding: 8px;" class="text-area"
            readonly="true"></textarea>
        </div>
      </div>
      <!-- <div class="dialog-body"
        style="padding-top: 100px; padding-bottom: 30px; height: 100%; margin: -100px 0 0; overflow-y: hidden; ">
        <textarea :value="content" style="width: 100%; height: 100%; resize: none;" readonly="true"></textarea>
      </div> -->
    </div>
  </div>
</template>
<script>
  import dayjs from 'dayjs'

  export default {
    computed: Vuex.mapState({
      maintenanceType: 'maintenanceType',
      maintenance: 'maintenance',
      isShow () {
        const maintenance = this.$store.state.maintenance
        // 已存在 maintenance-portal/product-time 这个 cookie，则返回 true，表明不显示 checkBox
        if (maintenance.startTime) {
          let cookieName = `maintenance-${this.$store.state.maintenanceType}-${maintenance.startTime.replace(' ', '-').replace(/:/g, '-')}`
          let result = document.cookie.indexOf(`${cookieName}=true`) === -1
          let date = new Date()
          date.setTime(date.getTime() + 3 * 24 * 3600 * 1000);
          document.cookie = `${cookieName}=true;expires=${date.toGMTString()}`
          return result
        }
        return true
      },
      startTime () {
        const maintenance = this.$store.state.maintenance
        if (maintenance.startTime) {
          return dayjs(new Date(maintenance.startTime.replace(/-/g, '/')).getTime()).format('YYYY-MM-DD HH:mm')
        }
        return ''
      },
      endTime () {
        const maintenance = this.$store.state.maintenance
        if (maintenance.startTime) {
          return dayjs(new Date(maintenance.startTime.replace(/-/g, '/')).getTime() + maintenance.splash * 60 * 1000).format('YYYY-MM-DD HH:mm')
        }
        return ''
      },
      content () {
        let contents = []
        const maintenance = this.$store.state.maintenance
        if (maintenance.contents) {
          let products = []
          for (let i = 0; i < maintenance.products.length; i++) {
            let code = maintenance.products[i]
            let name = maintenance.productNames[i]
            if ((this.$store.state.maintenanceType === 'portal' && (code === 'iwop-portal' || code === 'other')) ||
              (this.$store.state.maintenanceType === 'product' && (code !== 'iwop-portal' && code !== 'other')))
              products.push({ code, name })
          }

          products.forEach(item => {
            let productContents = maintenance.contents[item.code].split('\n')
            if (item.code !== 'other') {
              contents.push(`${item.name}：`)
              for (let i = 0; i < productContents.length; i++) {
                productContents[i] = `    ${productContents[i]}`
              }
            }
            contents = contents.concat(productContents)
            contents.push('')
          })
          return contents.join('\n')
        }
        return ''
      }
    }),
    methods: {
      hiddenClick (e) {
        const maintenance = this.$store.state.maintenance
        // 已存在 maintenance-portal/product-time 这个 cookie，则返回 true，表明不显示 checkBox
        if (maintenance.startTime) {
          let cookieName = `maintenance-${this.$store.state.maintenanceType}-${maintenance.startTime.replace(' ', '-').replace(/:/g, '-')}`
          let date = new Date()
          date.setTime(date.getTime() + (e.target.checked ? 3 * 24 * 3600 * 1000 : -1));
          document.cookie = `${cookieName}=true;expires=${date.toGMTString()}`
        }
      },
      hide () {
        if (document.querySelector('#refreshHidden') && document.querySelector('#refreshHidden').checked) {
          document.querySelector('#refreshHidden').style.display = 'none'
          document.querySelector('#spanForRefreshHidden').style.display = 'none'
        }
        document.querySelector('#maintenance-modal').style.display = 'none'
        document.querySelector('#maintenance-modal').style.visibility = 'hidden'
        document.querySelector('#maintenance-modal > .dialog').style.opacity = 0
      }
    }
  }
</script>
