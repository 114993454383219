import './login.html'
import './css/index'

import store from './store/index'
import layout from './components/layout.vue'

(function () {
  return new Vue({
    el: '#layout',
    template: '<cmp-layout></cmp-layout>',
    store,
    components: {
      'cmp-layout': layout
    },
    mounted: function () {
      this.$nextTick(() => {
        this.$store.dispatch('getLoginConfig')
        // this.$store.dispatch('checkCaptcha')
      })
    }
  })
})()
