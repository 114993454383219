const max = 10000 // ms

function start () {

}

function end () {

}

export default {
  start,
  end
}
