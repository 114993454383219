const mutations = {
  setErrorMessage: (state, message) => {
    state.errorMessage = message
  },
  setLoginConfig: (state, config) => {
    // if (config && !config.valid && config.domain) {
    //   alert(document.location.href)
    //   alert(config.domain)
    //   if (document.location.href.indexOf(config.domain) === -1) {
    //     document.location.replace(config.domain)
    //   }
    // } else if (config && config.valid && config.domain) {
    if (config && config.valid && config.domain) {
      state.domain = config.domain
      state.tenantName = config.name
    } else {
      state.tenantName = '智慧工地开放平台'
    }
    if (config.maintenance && (config.maintenance.deploys.includes('portal') || config.maintenance.deploys.includes('other'))) {
      state.maintenance = config.maintenance
    } else {
      state.maintenance = {} // 手工触发 watch，防止覆盖登录输入框
    }
    state.login = config.login
  },
  setShowCaptcha: (state, showCaptcha) => {
    state.showCaptcha = showCaptcha
  },
  setDestUrl: (state, destUrl) => {
    state.destUrl = destUrl
  },
  setCoolDown: (state, coolDown) => {
    state.coolDown = coolDown
  },
  decCoolDown: (state, coolDown) => {
    state.coolDown--
  }
}

export default mutations
